/* Base16 Atelier Cave Dark - Theme */
/* by Bram de Haan (http://atelierbram.github.io/syntax-highlighting/atelier-schemes/cave) */
/* Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16) */

/* Atelier-Cave Comment */
.hljs-comment,
.hljs-quote {
  color: #7e7887;
}

/* Atelier-Cave Red */
.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-regexp,
.hljs-link,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
  color: #be4678;
}

/* Atelier-Cave Orange */
.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
  color: #aa573c;
}

/* Atelier-Cave Green */
.hljs-string,
.hljs-symbol,
.hljs-bullet {
  color: #2a9292;
}

/* Atelier-Cave Blue */
.hljs-title,
.hljs-section {
  color: #576ddb;
}

/* Atelier-Cave Purple */
.hljs-keyword,
.hljs-selector-tag {
  color: #955ae7;
}

.hljs-deletion,
.hljs-addition {
  color: #19171c;
  display: inline-block;
  width: 100%;
}

.hljs-deletion {
  background-color: #be4678;
}

.hljs-addition {
  background-color: #2a9292;
}

.hljs {
  display: block;
  overflow-x: auto;
  background: #19171c;
  color: #8b8792;
  padding: 0.5em;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
